
// Class Definition
var RegisterComponent = function() {
    var initValidation = function() {
        validator = formEl.validate({
            ignore: ":hidden",
            errorClass: "is-invalid",
            rules: {
                
                name: {
                    required: true
                },
                email: {
                    required: true,
                    email: true
                },
                cellphone: {
                    required: true,
                    telefone: true,
                },
                password: {
                    required: true
                },
                password_verified_at: {
                    required: true
                },
                terms: {
                    required: true
                },
            },
            // Submit valid form
            submitHandler: function (form) {
                apiClient.head('/sanctum/csrf-cookie')
                .then(response => {
                    apiClient.post(formEl.attr('action'), new FormData(formEl[0])).then(response => {
                        console.log(response.data)
                        if(response.data.status=="success"){
                            $('#modal-register').hide();
                            Swal.fire({
                                    title: 'Cadastro realizado com sucesso!',
                                    icon: 'success',
                                    html:'',
                                    showCloseButton: false,
                                    focusConfirm: false,
                                    confirmButtonText:
                                    '<i class="fa fa-thumbs-up"></i> ok!',
                                    confirmButtonAriaLabel: 'Thumbs up, great!',
                                    willClose: () => {
                                        window.location.reload();
                                    }
                            }).then(function () {

                            });

                        }
                    }).catch(function (error) {
                        HandlingError(error, validator);
                    });
                }).catch(function (error) {
                    HandlingError(error, validator);
                });
                
                return false;
            }

        });
    }

    return {
        init: function() {
            formEl = $('#RegisterComponentForm');
            initValidation();
        }
    };
}();

// Class Initialization
$(function(){
    RegisterComponent.init();
    
    
});